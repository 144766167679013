import { useMutation } from "react-query";
import useApi from "./useApiRequest";

export const GetTagAjuste = ()  => {
    const api = useApi();

    const mutate = async (params) => {
        const data  = await api.get(`/etiquetas/ajuste/data?code=${params.code}&alm=${params.alm}`);
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        getTagAjusteMutation: mutation.mutate,
    };
}
export const CreateAjuste = ()  => {
    const api = useApi();

    const mutate = async (params) => {
        const data  = await api.post(`/ajustes-inventario`, params, {
            dataType: "json",
            hasFormData: false,
            hasCustomUrl: false,
        });
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        crearAjusteMutation: mutation.mutate,
    };
}
export const GetAjuste = ()  => {
    const api = useApi();

    const mutate = async (params) => {
        const data  = await api.get(`/ajustes-inventario/${params}`);
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        getAjusteMutation: mutation.mutate,
    };
}
export const ReactivarEtiqueta = ()  => {
    const api = useApi();

    const mutate = async (params) => {
        const data  = await api.put(`/ajustes-inventario/reactivar/${params}`, {}, {
            dataType: "json",
            hasFormData: false,
            hasCustomUrl: false,
        });
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        reactivarEtiquetaMutation: mutation.mutate,
    };
}