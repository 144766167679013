import { Button, Divider, Grid } from "@mui/material"
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { GetDocumentos } from "../../../hooks/getDocumentosByTipo";
import { useEffect, useState } from "react";
import { TipoDocumentoEnum } from "../../../@enums/tipo_documento";
import { TablaAjustes } from "./tablaAjustes";
import { HEADER_DATA_UPDATE } from "../../../redux/reducers/header.reducer";


export const AjusteInventarioIndex = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {getDocumentosMutation} = GetDocumentos();
  const [returns, setReturns] = useState([]);
  const [filtro, setFiltro] = useState('');

  const goNuevo = () => {
    navigate(`/inventarios/ajuste-inventario/nuevo`);
  }
  const get_docs = () => {
    getDocumentosMutation({tipo:TipoDocumentoEnum.AJUSTE, clave: filtro}, {
      onSuccess: (data) => {
        setReturns(data)
      },
      onError: (error) => {
        console.log(error)
      }
    })
  }
  useEffect(() => {
    dispatch(
      HEADER_DATA_UPDATE({nombreData: null})
    )
  }, []);

  const handleInputChange = (event) => {
    setFiltro(event.target.value);
  };
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      get_docs()
    }
  };

  return(
    <>
      <Grid container spacing={0} sx={{height:'10vh'}}>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3} sx={{padding:'10px 30px', height:'100%'}} className="center_item">
          <Button variant="contained" className="btn_system" onClick={goNuevo}>
            + Nuevo ajuste
          </Button>
        </Grid>
      </Grid>
      <Divider />
      <Grid container spacing={0} sx={{height:'80vh'}} >
        <Grid item xs={12} sx={{padding:'10px 20px', height:'100%'}} className="center_item">
          <TablaAjustes  key={'ajustes_table'} sample={returns} filtro={filtro} handleInputChange={handleInputChange} onPressEnter={handleKeyDown}/>
        </Grid>
      </Grid>
    </>
  )
}