import { Autocomplete, Box, Button, FormControl, FormHelperText, Grid, TextField, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { HEADER_DATA_UPDATE } from "../../../redux/reducers/header.reducer";
import { useDispatch } from "react-redux";
import { useLoading } from "../../../components/Loading/useLoading";
import { TiposAlmacenEnum } from "../../../@enums/tipo_almacen";
import { GetAllAlmacenesList } from "../../../hooks/getListAlmacen";
import { TipoDocumentoEnum } from "../../../@enums/tipo_documento";
import { EstatusDocumentoEnum } from "../../../@enums/status_documento.enum";
import moment from "moment";
import swal from 'sweetalert2'
import { ItemAjuste } from "./itemAjuste";
import { CreateAjuste, GetTagAjuste } from "../../../hooks/ajuste";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";



export const NuevoAjuste = () => {

  const formData = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {setAppLoading} = useLoading();
  const { enqueueSnackbar } = useSnackbar();
  const { getAllAlmacenesListMutation } = GetAllAlmacenesList();
  const { getTagAjusteMutation } = GetTagAjuste();
  const { crearAjusteMutation } = CreateAjuste();
  const [almacenes, setAlmacenes] = useState([]);
  const [errAlm, setErrAlm] = useState(false);
  const [errTags, setErrTags] = useState(false);
  const [headData, setHeadData] = useState(
    {
      alma_id: null,
      alma_name: null,
      docu_tipo: TipoDocumentoEnum.AJUSTE,
      docu_estatus: EstatusDocumentoEnum.SIN_ESTATUS,
      docu_fecha: moment().format('YYYY-MM-DD HH:mm:ss') 
    }
  )
  const [tags, setTags] = useState([])

  useEffect(() => {
    dispatch(
      HEADER_DATA_UPDATE({nombreData: 'Nuevo Ajuste'})
    )
  }, []);
  useEffect(() => {
    get_almacenes();
  }, []);

  //ALMACENES
  const get_almacenes = () => {
    setAppLoading(true)
    getAllAlmacenesListMutation(
      {},
      {
        onSuccess: (data) => {
          let itms = data.filter(item => item.tipo === TiposAlmacenEnum.ALMACEN);
          console.log(itms)
          setAlmacenes(itms);
          setAppLoading(false)
        },
        onError: (error) => {
          setAppLoading(false)
          console.log(error);
        },
      }
    );
  };
  const handleKeyUp = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };
  const changeAlmacen = (value) => {
    if(headData.alma_id !== null){
      swal.fire({
        title: "¿Esta seguro que desea cambiar de almacén?",
        text: "Perderá todos los movimientos registrados",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, cambiar"
      }).then((result)=>{
        if (result.isConfirmed) {
          if(value !== null){
            setHeadData({
              ...headData, 
              alma_id: value.id,
              alma_name: value.label
            });
          }else{
            setHeadData({
              ...headData, 
              alma_id: null,
              alma_name: null
            })
          }
          setTags([]);
        }
      })
    }else{
      if(value !== null){
        setAppLoading(true);
        setHeadData({...headData, alma_id: value.id, alma_name: value.label});    
      }else{
        setHeadData({...headData, alma_id: null, alma_name: null});
      }
    }
    formData.current.querySelector('#tag').value = ''
    setAppLoading(false);
  }

  //ETIQUETAS
  const get_data_tag = (event) => {
    if (event.key === 'Enter' || event.key === 'Tab'){
      event.preventDefault();
      let exist = tags?.some(item => item?.tag === event.target.value);
      if(exist){
        enqueueSnackbar( 'La etiqueta ya ha sido agregada', {
          autoHideDuration: 2000,
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        });
      }else{
        getTagAjusteMutation({code: event.target.value, alm:headData.alma_id},{
          onSuccess: (data) => {
            if(data?.success === false){
              enqueueSnackbar( data?.mensaje, {
                autoHideDuration: 2000,
                variant: "error",
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "center",
                },
              });
            }else{
              setTags([...tags, data])
            }
          },
          onError: (error) => {
            console.log(error)
          }
        })
      }
      formData.current.querySelector('#tag').value = ''
    }
  }
  
  //SEND DATA
  const send_data = () => {
    if(headData.alma_name && tags.length > 0){
      swal.fire({
        title: "¿Esta seguro que desea realizar el ajuste de almacén?",
        text: "Se eliminarán las masters y todos los movimientos que no han sido agregados en el almacén",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, realizar ajuste"
      }).then((result)=> {
        if(result.isConfirmed){
          let body = {
            ...headData,
            tags: tags
          }
          setAppLoading(true);
          crearAjusteMutation(body, {
            onSuccess: (data) => {
              console.log(data);
              setAppLoading(false);
              navigate(`/inventarios/ajuste-inventario`);
            },
            onError: (error) => {
              console.log(error);
              setAppLoading(false);
            }
          })
        }
      })
      
    }else{
      if(headData.alma_id === null) setErrAlm(true);
      if(tags.length === 0) setErrTags(true)
    }
  }

  return(
    <>
      <form ref={formData} id="form_dom" style={{ width: "100%" }}>
        <Grid container spacing={0}>
          <Grid item xs={12} sx={{p:1, textAlign:'start', display:'flex', justifyContent:'space-between', alignItems:'center', mb:2}}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
                Datos Ajuste
            </Typography>
            <Button variant="contained" className="btn_system" sx={{width:150, ml:1}} onClick={send_data}>
                Guardar
            </Button>                
          </Grid>
          <Grid item xs={12} sx={{p:1, textAlign:'start', display:'flex', justifyContent:'space-between', alignItems:'center', mb:2}}>
            <Grid container spacing={0}>
              <Grid item xs={12} sm={12} md={6} lg={2} xl={3} sx={{p:1, display:'flex', justifyContent:'center'}}>
                <FormControl fullWidth  size="small" error={errAlm}>
                  <Autocomplete
                    fullWidth
                    id="alm_id"
                    key={`alm_${headData?.alma_id}`}
                    getOptionLabel={(opcion) => opcion.label}
                    filterSelectedOptions
                    options={almacenes}
                    onKeyDown={handleKeyUp}
                    value={almacenes?.find(o => o?.id === headData?.alma_id)}
                    onChange={(e, newValue)=>{
                        if(newValue === null){
                          changeAlmacen(null)
                        }else{
                          changeAlmacen(newValue)
                        }
                    }}
                    renderOption={(props, option)=> (
                        <Box {...props} key={option?.id} sx={{width: '100%'}}>{option.label}</Box>
                    )}
                    renderInput={(params) => 
                        <TextField {...params} name="alma_id" label="Almacen" size="small" fullWidth  required/>
                    }
                  />
                  <FormHelperText>
                    {errAlm ? "Seleccione un almacen" : ""}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={2} xl={2} sx={{p:1, display:'flex', justifyContent:'center'}}>
                <TextField
                  id='tag'
                  fullWidth
                  size="small"
                  name="tag"
                  label="Etiqueta"
                  defaultValue={null}
                  disabled={!headData.alma_id}
                  onKeyDown={(e)=>get_data_tag(e)}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{p:1, textAlign:'start', display:'flex', justifyContent:'space-between', alignItems:'center', mb:2}}>
            <ItemAjuste key='item_ajuste' tags={tags} setTags={setTags}/>
          </Grid>
        </Grid>
      </form>
    </>
  )
}