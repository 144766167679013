import { Delete } from "@mui/icons-material"
import { Chip, Grid } from "@mui/material"


export const ItemAjuste = ({tags, setTags}) => {

  const delete_tag = (index_split) => {
    let array = tags;
    array.splice(index_split, 1);
    setTags([...array]);
  }

  return(
    <>
      <Grid
        container
        spacing={0}
        sx={{
          p: 1,
          textAlign: "start",
          display: "flex",
          alignItems: "center",
          border: '1px solid #D9D9D9',
          borderRadius: 2,
          width: '100%'
        }}
      >
        {tags?.map((tag, index_split) => (
          <Grid
            item
            xs={3}
            sm={3}
            md={3}
            lg={3}
            xl={3}
            sx={{
              p: 1,
              textAlign: "start",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Chip
              label={
                <>
                  <div
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    display: "flex",
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    maxWidth: '100%'
                  }}
                  >
                    <span
                      style={{
                        marginRight: 7,
                        fontSize: 14,
                        fontWeight: 400,
                        color: "black",
                      }}
                    >
                      {tag?.quantity}
                    </span>
                    <span
                      style={{
                        marginLeft: 7,
                        border: "1px solid #575E3B",
                        borderRadius: 4,
                        fontSize: 11,
                        padding: 3,
                        color: "#575E3B"
                      }}
                    >
                        {tag?.tag}
                    </span>
                  </div>
                  <div
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    width: '100%',
                    maxWidth: '100%',
                    textAlign: 'center'
                  }}
                  >
                    <span
                      style={{
                        marginLeft: 7,
                        fontSize: 15,
                        padding: 3,
                        color: "black"
                      }}
                    >
                      {`${tag?.name}`}
                    </span>
                    <br/>
                    <span
                      style={{
                        marginLeft: 7,
                        fontSize: 15,
                        padding: 3,
                        color: "black"
                      }}
                    >
                      {`${tag?.code}`}
                    </span>
                  </div>
                </>
              }
              variant="outlined"
              deleteIcon={
                <Delete sx={{ marginLeft: "1px !important" }} />
              }
              onDelete={()=>delete_tag(index_split)}
              sx={{
                height: 85,
                borderRadius: 2,
                display: "flex",
                justifyContent: "center",
                alignItems: 'center',
                width: "100%",
                maxWidth: '100%',
                backgroundColor:"transparent",
              }}
            />
          </Grid>
        ))}
      </Grid>
    </>
  )
}