import { FormatListNumbered, RouteOutlined } from "@mui/icons-material";
import { Box, CssBaseline, styled } from "@mui/material";
import { Route, Routes, useLocation } from "react-router-dom";
import AppBarComponent from "../components/appbar";
import { ProtectedRoutes } from "./private_routes";
import { ExistenciasIndex } from "../views/reportes/existencias/existenciasIndex";
import { AccessRoutes } from "./access_route";
import { TrazabilidadIndex } from "../views/reportes/trazabilidad/trazabilidadIndex";
import InactivityTimer from "../components/timerActivity";
import swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { AUTH_LOGOUT } from "../redux/reducers/auth.reducer";

export const ReportesRouter = () => {
  const menu_options = [
    {
      id: 1,
      name: "Existencias",
      icon: <FormatListNumbered />,
      redirect: "/reportes/existencias",
    },
    {
      id: 2,
      name: "Trazabilidad",
      icon: <RouteOutlined />,
      redirect: "/reportes/trazabilidad",
    },
  ];

  const location = useLocation();
  const dispatch = useDispatch();
  let path = location?.pathname.split("/").filter((item) => item !== "");

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));

  const onInactive = () => {
    swal
    .fire({
      position: "center",
      icon: "warning",
      title: "Tiempo de sesión expirado por inactividad",
      showConfirmButton: false,
      timer: 10000,
    })
    .then(() => {
      dispatch(
        AUTH_LOGOUT()
      );
    });
  }

  return (
    <>
      {path[0] === "reportes" && (
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBarComponent
            key={"app_bar_inventario"}
            menuOptions={menu_options}
          />
          <Box component="main" sx={{ flexGrow: 1, p: 0 }}>
            <DrawerHeader />
            <Routes>
              <Route element={<ProtectedRoutes />}>
                <Route
                  key="existencias_index"
                  path="/reportes/existencias"
                  element={
                    <AccessRoutes
                      key={"access_existencias"}
                      permission={"MRP:Reportes:Existencias:*"}
                    >
                      <ExistenciasIndex key='exitencias_module'/>
                    </AccessRoutes>
                  }
                />
                <Route
                  key="trazabilidad_index"
                  path="/reportes/trazabilidad"
                  element={
                    <AccessRoutes
                      key={"access_trazabilidad"}
                      permission={"MRP:Reportes:Trazabilidad:*"}
                    >
                      <TrazabilidadIndex key='exitencias_module'/>
                    </AccessRoutes>
                  }
                />
              </Route>
            </Routes>
            <InactivityTimer key='inactivity_component' onInactive={onInactive}/>
          </Box>
        </Box>
      )}
    </>
  );
};
