import { Grid, InputAdornment, Paper, TextField } from "@mui/material";
import { GroupingTable } from "../../../components/Table/grouping_table";
import { Search } from "@mui/icons-material";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ThemeContext } from "@emotion/react";
import moment from "moment";

export const TablaAjustes = ({sample, filtro, handleInputChange, onPressEnter}) => {
  const theme = useContext(ThemeContext);
  const navigate = useNavigate();
  const sort = {}
  
  const rows = sample;
  const columns = [
    {
      accessorKey: 'docu_folio',
      header: 'Folio',
    }, 
    {
      accessorFn: (row) => moment(row?.docu_fecha, "YYYY-MM-DD HH:mm:ss").format("DD-MM-YYYY") || '',
      id: 'fecha',
      header: 'Fecha',
      sortingFn: 'text',
      filterFn: 'includesString',
    },
    {
      accessorFn: (row) => JSON.parse(row?.docu_extradata).almacen || '',
      id: 'almacen',
      header: 'Almacen',
      sortingFn: 'text',
      filterFn: 'includesString',
    },
  ];

  const redirect = (row) => {
    if(row){
      navigate(`/inventarios/ajuste-inventario/${row?.docu_id}`);
    }
  }
  
  return(
    <Paper style={{ height: '100%', width: '100%' }} sx={{boxShadow:'none'}}>
      <Grid container spacing={0}>
        <Grid item xs={12} sx={{p:1, justifyContent: 'flex-end'}} className="center_item">
          <TextField
          id="search_entry"
          label="Ingrese palabra clave"
          name="search_entry"
          size="small"
          value={filtro}
          onChange={handleInputChange}
          onKeyDown={onPressEnter}
          InputProps={{
              startAdornment: (
              <InputAdornment position="start">
                  <Search sx={{fontSize:20}}/>
              </InputAdornment>
              ),
          }}
          sx={{ color: theme.palette.primary.input }}
          />
        </Grid>
      </Grid>
      <GroupingTable  
      key='tabla_devoluciones' 
      columnas={columns} 
      data={rows} 
      redirect={redirect}
      sort={sort}
      />
    </Paper>
  )
}