import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useLoading } from "../../../components/Loading/useLoading";
import { GetAjuste, ReactivarEtiqueta } from "../../../hooks/ajuste";
import { HEADER_DATA_UPDATE } from "../../../redux/reducers/header.reducer";
import { useEffect, useState } from "react";
import { Chip, Grid, Typography } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { useSnackbar } from "notistack";


export const Ajuste = () => {

  const { id } = useParams();
  const {setAppLoading} = useLoading();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const {getAjusteMutation} = GetAjuste();
  const {reactivarEtiquetaMutation} = ReactivarEtiqueta();
  const [ajuste, setAjuste] = useState(null)


  useEffect(() => {
    get_ajuste();
  }, []);

  const get_ajuste = () => {
    setAppLoading(false)
    getAjusteMutation(id, {
      onSuccess: (data) => {
        if(data){
          dispatch(
            HEADER_DATA_UPDATE({nombreData: `Ajuste Folio ${data?.docu_folio}`})
        )
          setAjuste(data)
        }
      },
      onError: (error) => {
        console.log(error)
      }
    })
  }

  //REACTIVAR ETIQUETA
  const delete_tag = (index, movdoc_id) => {
    setAppLoading(true);
    reactivarEtiquetaMutation(movdoc_id, {
      onSuccess: (data) => {
        console.log(data);
        if(data?.success){
          let array = ajuste;
          array.documentos_movimientos.splice(index, 1);
          setAjuste(array);
          enqueueSnackbar( 'La etiqueta ha sido reactivada', {
            autoHideDuration: 2000,
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center",
            },
          });
        }else{
          enqueueSnackbar( 'Ha habido un problema al reactivar', {
            autoHideDuration: 2000,
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center",
            },
          });
        }
        setAppLoading(false);
      },
      onError: (error) => {
        console.log(error);
        setAppLoading(false);
        enqueueSnackbar( 'Ha habido un problema al reactivar', {
          autoHideDuration: 2000,
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        });
      }
    })
  }

  return(
    <>
      {
        ajuste && (
          <Grid container spacing={0}>
            <Grid item xs={12} sx={{p:1, textAlign:'start', display:'flex', justifyContent:'space-between', alignItems:'center', mb:2}}>
                <Typography id="transition-modal-title" variant="h5" component="h2">
                    {`${JSON.parse(ajuste?.docu_extradata)?.almacen?.toUpperCase()}` }
                </Typography>               
            </Grid>
            <Grid item xs={12} sx={{p:1, textAlign:'start', display:'flex', justifyContent:'space-between', alignItems:'center', mb:2}}>
              <Grid container spacing={0} sx={{border: "1px solid #D9D9D9", borderRadius: 5, mb:3, p:1, display: 'flex'}}>
                <Grid item xs={12} className="container_split" >
                  <Grid
                    container
                    spacing={0}
                    sx={{
                      p: 1,
                      textAlign: "start",
                      display: "flex",
                      alignItems: "center",
                      border: '1px solid #D9D9D9',
                      borderRadius: 2,
                      width: 'auto'
                    }}
                  >
                    {
                      ajuste?.documentos_movimientos.map((doc_mov, index) => (
                        <Grid
                        item
                        xs={6}
                        sm={6}
                        md={4}
                        lg={3}
                        xl={3}
                        sx={{
                          p: 1,
                          textAlign: "start",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                        >
                          <Chip
                            label={
                              <>
                                <div
                                style={{
                                  backgroundColor: "transparent",
                                  border: "none",
                                  display: "flex",
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  width: '100%',
                                  maxWidth: '100%'
                                }}
                                >
                                  <span
                                    style={{
                                      marginRight: 7,
                                      fontSize: 14,
                                      fontWeight: 400,
                                      color: "black",
                                    }}
                                  >
                                    {JSON.parse(doc_mov?.domo_extradata)?.quantity}
                                  </span>
                                  <span
                                    style={{
                                      marginLeft: 7,
                                      border: "1px solid #575E3B",
                                      borderRadius: 4,
                                      fontSize: 11,
                                      padding: 3,
                                      color: "#575E3B"
                                    }}
                                  >
                                      {JSON.parse(doc_mov?.domo_extradata)?.tag}
                                  </span>
                                </div>
                                <div
                                style={{
                                  backgroundColor: "transparent",
                                  border: "none",
                                  width: '100%',
                                  maxWidth: '100%',
                                  textAlign: 'center'
                                }}
                                >
                                  <span
                                    style={{
                                      marginLeft: 7,
                                      fontSize: 15,
                                      padding: 3,
                                      color: "black"
                                    }}
                                  >
                                    {`${JSON.parse(doc_mov?.domo_extradata)?.name}`}
                                  </span>
                                  <br/>
                                  <span
                                    style={{
                                      marginLeft: 7,
                                      fontSize: 15,
                                      padding: 3,
                                      color: "black"
                                    }}
                                  >
                                    {`${JSON.parse(doc_mov?.domo_extradata)?.code}`}
                                  </span>
                                </div>
                              </>
                            }
                            variant="outlined"
                            deleteIcon={
                              <Delete sx={{ marginLeft: "1px !important" }} />
                            }
                            onDelete={()=>delete_tag(index, doc_mov?.domo_id)}
                            sx={{
                              height: 85,
                              borderRadius: 2,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: 'center',
                              width: "100%",
                              maxWidth: '100%',
                              backgroundColor:"transparent",
                            }}
                          />
                        </Grid>
                      ))
                    }
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )
      }
    </>
  )
}